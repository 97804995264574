import GetInTouch from '../../components/Home/GetInTouch/GetInTouch';
import Blogs from '../../components/Home/Blogs/Blogs';
import Comparison from '../../components/Home/Comparison/Comparison';
import Faq from '../../components/Home/Faq/Faq';
import Feature from '../../components/Home/Feature/Feature';
import Founders from '../../components/Home/Founders/Founders';
import Hero from '../../components/Home/Hero/Hero';
import Partners from '../../components/Home/Partners/Partners';
import Pricing from '../../components/Home/Pricing/Pricing';
import Proposition from '../../components/Home/Proposition/Proposition';
import SneakPeak from '../../components/Home/SneakPeak/SneakPeak';
import React from 'react';

import { motion } from 'framer-motion';


import './Home.css';
import { FaqData } from '../../utils/data';

const Home = ({resize}) => {


  return (
    <motion.div className={`home-container`}
            initial={{ y: -10, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: -10, opacity: 0 }}
            transition={{ ease:[0.76,0,0.24,1], duration:1 }}>
      <Hero resize={resize} />
      <Feature />
      <Partners />
      <Proposition />
      <SneakPeak />
      {/* <Comparison /> */}
      {/* <Pricing /> */}
      {/* <Founders /> */}
      <Blogs />
      <Faq FaqData={FaqData} forHomePage />
      <GetInTouch />
    </motion.div>
  )
}

export default Home